//@import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import '../src/styles/material3.css';
//@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
//@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';